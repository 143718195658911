<template>

  <marque-form
    :title="title"
    :submit-button-text="submitButtonText"
    @submitMarqueForm="onSubmit($event)"
  />

</template>

<script>

import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import MarqueForm from './MarqueForm.vue'

export default {
  components: {
    MarqueForm,
  },
  data() {
    return {
      title: "Ajout d'une marque",
      submitButtonText: 'Ajouter',
    }
  },
  setup() {
    const toast = useToast()

    const onSubmit = formMarqueValue => {
      store.dispatch('modeles/addMarque', formMarqueValue)
        .then(() => {
          router.push({ name: 'marques-list' })
        })
        .catch(error => {
          if (error.status === 409) {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Marque déjà existante',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: "Erreur lors de l'ajout d'une marque",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    }

    return {
      onSubmit,
    }
  },
}
</script>
